<template>
  <div class="limit">
    <div class="limit__content">
      <div class="limit__title">
        {{ $t("limit.edit_total") }}
      </div>

      <div class="limit__information">
        <div class="limit__information-item">
          <div class="limit__information-title">
            {{ $t("limit.company") }}
            <span>{{ companyData.name }}</span>
          </div>

          <div class="limit__information-data">
            {{ companyData.id }}
          </div>
        </div>

        <div class="limit__information-item">
          <div class="limit__information-title">
            {{ $t("limit.platform") }}
          </div>

          <div class="limit__information-data">
            {{ companyData.platform }}
          </div>
        </div>

        <div class="limit__information-item">
          <div class="limit__information-title">
            {{ $t("limit.spent") }}
          </div>

          <div class="limit__information-data">
            {{ companyData.spent ? companyData.spent : 0 }} {{ $t('currency.currency_symbol') }}
          </div>
        </div>

        <div class="limit__information-item">
          <div class="limit__information-title">
            {{ $t("limit.total_limit") }}
          </div>

          <div class="limit__information-data">
            {{ companyData.total_limit ? companyData.total_limit : 0 }} {{ $t('currency.currency_symbol') }}
          </div>
        </div>

        <div class="limit__information-item">
          <div class="limit__information-title">
            {{ $t("limit.new_total_limit") }}
          </div>

          <div class="limit__information-amount">
            <input
                v-model="limit"
                class="limit__information-input"
                type="number"
                :placeholder="$t('limit.add_limit')"
            >
            <span>{{ $t('currency.currency_symbol') }}</span>
          </div>
        </div>
        <div v-if="errors && errors.length" class="limit__information-errors">
          <span v-for="(err, key) in errors" :key="key + 'err'">
            {{ err }} <br>
          </span>
        </div>
      </div>
    </div>

    <div class="limit__bottom">
      <ButtonElement
          @click-button="save"
          :text="$t('button_props.save')"
          :disabled="limit === ''"
      />
      <ButtonElement
          :text="$t('button_props.cancel')"
          @click-button="closeDialog"
          view="empty"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";

export default {
  name: "EditingLimit",
  components: {
    ButtonElement,
  },
  computed: {
    companyData() {
      return this.$store.state.Company.companyData
    },
  },
  data() {
    return {
      limit: '',
      errors: [],
    }
  },
  watch: {
    limit() {
      this.errors = []
    },
  },
  methods: {
    async save() {
      const data = {
        account_id: this.companyData.accountId,
        business_account_id: this.companyData.businessAccountId,
        campaign_id: this.companyData.id,
        budget_limit: this.limit,
      }
      await this.$store.dispatch('Company/changeCampaignBudget', data)
          .then(() => {
            this.$notify({
              message: this.$t('limit.message_notify'),
              type: 'success'
            });
          })
          .catch(err => {
            if (err.response.data.errors) {
              for (let key in err.response.data.errors) {
                err.response.data.errors[key].forEach((el) => {
                  this.errors.push(el)
                })
              }
            }
            console.log(err.response.data.errors)
          })
    },
    closeDialog() {
      this.$store.dispatch('Dialog/closeDialog')
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

  .limit {
    background: #fff;
    color: #000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__content {
      padding: 8rem;
    }

    &__title {
      font-size: 3rem;
      line-height: 3.5rem;
      font-weight: 700;
      color: $basicBlack;
      margin: 0 0 2.5rem;
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8rem;

      .button {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--primary);
        outline: none;
        padding: 1.5rem 4rem;
        margin-right: 1rem;

        &:focus {
          border: 1px solid;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__information {

      &-item {
        border-bottom: 1px solid rgba($gray5, 0.5);
        padding: 1.5rem 0;

        &:last-child {
          border-bottom: none;
        }
      }

      &-errors {
        color: $redLight;
        margin-top: 2rem;
      }

      &-title, &-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.6rem;
        line-height: 1.9rem;
        font-weight: 700;
        color: $basicBlack;
        margin: 0 0 1rem;

        span {
          font-weight: 400;
          color: $gray2;
          margin: 0 0 0 2rem;
        }
      }

      &-data {
        font-weight: 400;
        margin-bottom: 0;
      }

      &-amount {
        display: flex;
        align-items: center;

        span {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.9rem;
          color: $basicBlack;
        }
      }

      &-input {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.9rem;
        color: $basicBlack;
        background: $basicWhite;
        border: 1px solid $gray4;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 1.5rem 1rem;
        margin: 0 2rem 0 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type=number] {
          -moz-appearance: textfield;
        }
      }
    }
  }

@include below(769px) {
  .limit__bottom{
    flex-wrap: wrap;
    .button{
      margin-bottom: 10px;
      margin-right: 0;
      width: 100%;
    }
  }
  .limit__bottom,
  .limit__content{
    padding: 15px;
    word-break: break-word;
  }
  .limit__title{
    margin-right: 30px;
    font-size: 22px;
    line-height: 1.3;
  }
}
</style>
